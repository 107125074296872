<template>
  <div class="info-table">
    <div style="display: flex">
      <span class="inner-title">血管信息{{ isReadonly ? '' : '录入' }}</span>
    </div>
    <div style="margin: 10px 0 0 10px">
      <div v-show="!isReadonly">
        <el-button
          size="mini"
          type="primary"
          class="commonBtn"
          @click="bloodVesselInsert"
          :disabled="signed_status"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="warning"
          class="commonBtn"
          @click="bloodVesselModify"
          :disabled="signed_status"
        >
          修改
        </el-button>
        <el-button
          size="mini"
          type="danger"
          class="commonBtn"
          @click="bloodVesselDelete"
          :disabled="signed_status"
        >
          删除
        </el-button>
      </div>

      <el-table
        class="inner-table"
        highlight-current-row
        :data="bloodVesselTableData"
        :header-cell-style="{ padding: 0 }"
        height="200px"
        style="width: 99%"
        @row-click="_rowClick"
        @row-dblclick="_doubleModify"
        border
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="lesionSite" label="病变部位"></el-table-column>
        <el-table-column prop="drainingArteryCount" label="引流动脉数量"></el-table-column>
        <el-table-column prop="drainingArteryName" label="引流动脉名称"></el-table-column>
        <el-table-column prop="drainageVeinCount" label="引流静脉数量"></el-table-column>
        <el-table-column prop="drainageVeinName" label="引流静脉名称"></el-table-column>
        <el-table-column prop="combinedAneurysm" label="是否合并动脉瘤"></el-table-column>
        <el-table-column prop="avmType" label="AVM类型"></el-table-column>
        <el-table-column prop="avmLevel" label="AVM分级"></el-table-column>
        <el-table-column prop="avmSize" label="AVM大小"></el-table-column>
        <el-table-column prop="functionBrainRegion" label="所在脑区功能"></el-table-column>
        <el-table-column prop="veinDrainageMode" label="静脉引流方式"></el-table-column>
        <el-table-column
          prop="hasInterventionalTherapy"
          label="既往有无介入治疗史"
        ></el-table-column>
        <el-table-column
          prop="hasCoilImplantation"
          label="既往弹簧圈/Onyx植入情况"
        ></el-table-column>
        <el-table-column prop="coilType" label="既往弹簧圈/Onyx类型"></el-table-column>
        <el-table-column prop="coilCount" label="既往弹簧圈植入数量"></el-table-column>
        <el-table-column prop="onyxCount" label="既往Onyx胶植入数量"></el-table-column>
        <el-table-column prop="collateralCirculationLevel" label="侧支循环分级"></el-table-column>
        <el-table-column prop="handlingOpinion" label="本次处理意见"></el-table-column>
      </el-table>
      <nerve-avm-info-dialog
        ref="NerveAvmInfoDialog"
        :nerveAvmId="nerveAvmId"
        :isReadonly="isReadonly"
        @save="_dialogSave"
      ></nerve-avm-info-dialog>
    </div>
    <div v-show="selectedInfo?.handlingOpinion?.includes('本次治疗')" class="surgery-information">
      <el-tabs type="border-card">
        <!-- 动静脉畸形栓塞术 -->
        <el-tab-pane
          :key="key_list[0]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            selectedInfo.handlingOpinion.includes('本次治疗') &&
            selectedInfo.handlingOpinion.includes('动静脉畸形栓塞术')
          "
        >
          <span slot="label">
            <i :class="nerveAvmEmbolizationDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            动静脉畸形栓塞术
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveAvmEmbolizationDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">治疗后畸形团残留</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="%"
                    v-model="nerveAvmEmbolizationDetail.residualDeformityMass"
                  />
                </div>

                <!-- <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AVM_embolization_residual_deformity_mass"
                  :value.sync="nerveAvmEmbolizationDetail.residualDeformityMass"
                /> -->
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入弹簧圈数量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="枚"
                    v-model="nerveAvmEmbolizationDetail.coilCount"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">该部位植入Onyx胶量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="ml"
                    v-model="nerveAvmEmbolizationDetail.onyxCount"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">术后并发症</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AVM_embolization_postoperative_complication"
                  :value.sync="nerveAvmEmbolizationDetail.postOperativeComplication"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 显微手术治疗 -->
        <el-tab-pane
          :key="key_list[1]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            selectedInfo.handlingOpinion.includes('本次治疗') &&
            selectedInfo.handlingOpinion.includes('显微手术治疗')
          "
        >
          <span slot="label">
            <i :class="nerveAvmMicroSurgeryDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            显微手术治疗
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">病灶是否完整切除</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveAvmMicroSurgeryDetail.completeExcisionLesion"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">治疗后畸形团残留</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="%"
                    v-model="nerveAvmMicroSurgeryDetail.residualDeformityMass"
                  />
                </div>

                <!-- <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AVM_embolization_residual_deformity_mass"
                  :value.sync="nerveAvmMicroSurgeryDetail.residualDeformityMass"
                /> -->
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">术后并发症</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AVM_microsurgery_postoperative_complication"
                  :value.sync="nerveAvmMicroSurgeryDetail.postOperativeComplication"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 伽马刀治疗 -->
        <el-tab-pane
          :key="key_list[2]"
          v-if="
            selectedInfo &&
            selectedInfo.handlingOpinion &&
            selectedInfo.handlingOpinion.includes('本次治疗') &&
            selectedInfo.handlingOpinion.includes('伽马刀治疗')
          "
        >
          <span slot="label">
            <i :class="nerveAvmGamMaKnifeDetail.id != null ? 'icon-finished' : 'icon-warn'"></i>
            伽马刀治疗
          </span>
          <div class="infoEntry-table-tab">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">该部位介入是否成功</div>
                <cqt-radio
                  style="margin-left: 15px"
                  :value.sync="nerveAvmGamMaKnifeDetail.isSucceed"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">畸形团闭塞情况</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AVM_gamma_knife_occlusion_malformed_mass"
                  :value.sync="nerveAvmGamMaKnifeDetail.occlusionMalformedMass"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">靶点数</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="个"
                    v-model="nerveAvmGamMaKnifeDetail.targetCount"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">中心剂量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="Gy"
                    v-model="nerveAvmGamMaKnifeDetail.centralDose"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">边缘剂量</div>
                <div style="padding-left: 15px">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="Gy"
                    v-model="nerveAvmGamMaKnifeDetail.marginalDose"
                  />
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">术后并发症</div>
                <cqt-select
                  style="margin-left: 15px"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AVM_gamma_knife_postoperative_complication"
                  :value.sync="nerveAvmGamMaKnifeDetail.postOperativeComplication"
                />
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import NerveAvmInfoDialog from './NerveAvmInfoDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'
export default {
  name: 'NerveAneurysmInfoEntry',
  components: {
    NerveAvmInfoDialog,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  model: {
    prop: 'nerveAvmId',
    event: 'change'
  },
  props: {
    nerveAvmId: {
      type: String,
      require: true
    },
    signed_status: {
      type: Boolean,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    selectedInfo: {
      handler(val) {
        if (val) {
          this.getAllSuggestions(val)
        }
      },
      immediate: true
    },
    allDetail: {
      handler(val) {
        if (val) {
          this._initInfoValue()
        }
      }
    }
  },
  data() {
    return {
      bloodVesselTableData: [],
      selectedInfo: null,
      tableKey: null,
      key_list: [1, 2, 3],
      tabs_index: '0',
      nerveAvmEmbolizationDetail: {
        informationId: null, //动静脉信息id
        isSucceed: null, //该部位介入是否成功
        residualDeformityMass: null, //治疗后畸形团残留
        coilCount: null, //该部位植入弹簧圈量
        onyxCount: null, //该部位植入Onyx胶量
        postOperativeComplication: null //术后并发症
      },
      nerveAvmMicroSurgeryDetail: {
        informationId: null, //动静脉信息id
        completeExcisionLesion: null, //病灶是否完整切除
        residualDeformityMass: null, //治疗后畸形团残留
        postOperativeComplication: null //术后并发症
      },
      nerveAvmGamMaKnifeDetail: {
        informationId: null, //动静脉信息id
        isSucceed: null, //该部位介入是否成功
        occlusionMalformedMass: null, //畸形团闭塞情况
        targetCount: null, //靶点数
        centralDose: null, //中心剂量
        marginalDose: null, //边缘剂量
        postOperativeComplication: null //术后并发症
      }
    }
  },
  created() {},
  methods: {
    // 页码切换
    handleClick(tab) {
      this.tabs_index = tab.index
    },

    async _initInfoValue() {
      if (this.nerveAneurysmId == '-1') return
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.nerveAvmInformation.id}`
      let formData = new FormData()
      formData.append('elementName', 'nerveAvmInformation')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            console.log('bloodVesselTableData', res.data)
            this.bloodVesselTableData = res.data
            for (let i = 0; i < this.bloodVesselTableData.length; i++) {
              // 耗材
              const element = this.bloodVesselTableData[i]
              // 处理意见handlingSuggestions表示有二级分类
              if (element.handlingOpinion) {
                this.getAllSuggestions(element, 'init')
              }
            }
          }
        },
        () => {}
      )
    },
    getAllSuggestions(val, type) {
      if (!val.handlingOpinion?.includes('本次治疗')) return
      // 动静脉畸形栓塞术
      if (val.handlingOpinion?.includes('动静脉畸形栓塞术')) {
        this._getNerveAvmEmbolizationInfo(val, type)
      }
      // 显微手术治疗
      if (val.handlingOpinion?.includes('显微手术治疗')) {
        this._getNerveAvmMicroSurgeryInfo(val, type)
      }
      // 伽马刀治疗
      if (val.handlingOpinion?.includes('伽马刀治疗')) {
        this._getNerveAvmGamMaKnifeInfo(val, type)
      }
    },

    bloodVesselInsert() {
      // if (this.nerveAvmId == '-1' || !this.nerveAvmId)
      //   return this.$message.warning('请先完善动脉基本信息')
      this.$refs.NerveAvmInfoDialog.Show()
    },
    bloodVesselModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条血管信息')
      if (this.selectedInfo) {
        this.$refs.NerveAvmInfoDialog.Show(this.selectedInfo)
      }
    },
    bloodVesselDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条动脉信息')
      this.$confirm('是否删除该血管信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.selectedInfo.id) {
            const index = this.bloodVesselTableData.findIndex(
              (item) => item.id === this.selectedInfo.id
            )
            if (index !== -1) {
              this.bloodVesselTableData.splice(index, 1)
            }
          } else {
            const index = this.bloodVesselTableData.findIndex(
              (item) => item.ids === this.selectedInfo.ids
            )
            if (index !== -1) {
              this.bloodVesselTableData.splice(index, 1)
            }
          }
          this.selectedInfo = null
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _rowClick(row) {
      // if (this.isReadonly) return
      this.selectedInfo = null
      this.selectedInfo = row

      if (row.id) {
        this.key_list = [
          row.id,
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000),
          row.id + Math.random(0, 1000)
        ]
      } else {
        this.key_list = [
          row.ids,
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000)
        ]
      }
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.NerveAvmInfoDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      // this.selectedInfo = null  // 无需清空
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.bloodVesselTableData.length; i++) {
          const element = this.bloodVesselTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.bloodVesselTableData[i] = val
            this.$set(this.bloodVesselTableData, i, this.bloodVesselTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.bloodVesselTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.bloodVesselTableData.length; i++) {
          const element = this.bloodVesselTableData[i]
          if (val.id === element.id) {
            this.bloodVesselTableData[i] = val
            this.$set(this.bloodVesselTableData, i, this.bloodVesselTableData[i])
            break
          }
        }
      }
    },

    // 动静脉畸形栓塞术
    async _getNerveAvmEmbolizationInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveAvmEmbolization')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveAvmEmbolization = res.data.data[0]
            this.nerveAvmEmbolizationDetail = val.nerveAvmEmbolization
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveAvmEmbolizationDetail = val.nerveAvmEmbolization || {
          ...this.$options.data().nerveAvmEmbolizationDetail
        }

        val.nerveAvmEmbolization = this.nerveAvmEmbolizationDetail
      }
    },
    _clearNerveAvmEmbolizationInfo() {
      if (this.isReadonly) return
      this.nerveAvmEmbolizationDetail = {
        uuid: null, //主键id，带上为修改或删除，不带为新增
        informationId: null, //动静脉信息id
        isSucceed: null, //该部位介入是否成功
        residualDeformityMass: null, //治疗后畸形团残留
        coilCount: null, //该部位植入弹簧圈量
        onyxCount: null, //该部位植入Onyx胶量
        postOperativeComplication: null //术后并发症
      }
    },

    // 显微手术治疗
    async _getNerveAvmMicroSurgeryInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveAvmMicrosurgery')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveAvmMicrosurgery = res.data.data[0]
            this.nerveAvmMicroSurgeryDetail = val.nerveAvmMicrosurgery
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveAvmMicroSurgeryDetail = val.nerveAvmMicrosurgery || {
          ...this.$options.data().nerveAvmMicroSurgeryDetail
        }
        val.nerveAvmMicrosurgery = this.nerveAvmMicroSurgeryDetail
      }
    },
    _clearNerveAvmMicroSurgeryInfo() {
      this.nerveAvmMicroSurgeryDetail = {
        uuid: null, //主键id，带上为修改或删除，不带为新增
        informationId: null, //动静脉信息id
        completeExcisionLesion: null, //病灶是否完整切除
        residualDeformityMass: null, //治疗后畸形团残留
        postOperativeComplication: null //术后并发症
      }
    },

    // 伽马刀治疗
    async _getNerveAvmGamMaKnifeInfo(val, type) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'nerveAvmGammaKnife')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.nerveAvmGammaKnife = res.data.data[0]
            this.nerveAvmGamMaKnifeDetail = val.nerveAvmGammaKnife
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        this.nerveAvmGamMaKnifeDetail = val.nerveAvmGammaKnife || {
          ...this.$options.data().nerveAvmGamMaKnifeDetail
        }
        val.nerveAvmGammaKnife = this.nerveAvmGamMaKnifeDetail
      }
    },
    _clearNerveAvmGamMaKnifeInfo() {
      this.nerveAvmGamMaKnifeDetail = {
        uuid: null, //主键id，带上为修改或删除，不带为新增
        informationId: null, //动静脉信息id
        isSucceed: null, //该部位介入是否成功
        occlusionMalformedMass: null, //畸形团闭塞情况
        targetCount: null, //靶点数
        centralDose: null, //中心剂量
        marginalDose: null, //边缘剂量
        postOperativeComplication: null //术后并发症
      }
    }
  }
}
</script>

<style scoped lang="less">
.infoEntry-table-tab {
  display: flex;
  width: 99%;
  padding: 15px 0;
}
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
.button-panel {
  width: 100%;
  bottom: 0;
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-around;
}
.icon-finished::before {
  content: url('../../../../assets/img/finished.png');
}
.icon-warn::before {
  content: url('../../../../assets/img/warn.png');
}

.surgery-information {
  // border-style: solid;
  //     border-width: medium;
  //     border-color: #E4E7ED;
  margin-top: 30px;
  margin-bottom: 20px;

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;
    .input_has_unit {
      margin: 0;
    }
  }
  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    width: 140px;
  }
}
</style>
