<template>
  <el-checkbox-group v-model="valueList" style="flex: 1">
    <el-checkbox
      v-for="(item, index) in tableList"
      :key="index"
      :label="item.value"
    >
      {{ item.label }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  data() {
    return {
      innerValue: "",
      valueList: [],
      tableList: [
        {
          value: "动静脉畸形栓塞术",
          label: "动静脉畸形栓塞术",
        },
        {
          value: "显微手术治疗",
          label: "显微手术治疗",
        },
        {
          value: "伽马刀治疗",
          label: "伽马刀治疗",
        },
      ],
    };
  },

  model: {
    prop: "value",
    event: "change",
  },
  props: ["currentTreatment"],
  mounted() {
    var a = this.currentTreatment.split("|");
    // var a = this.currentTreatment.split("+");
    this.valueList = a;
  },
  watch: {
    valueList: {
      handler(val) {
        if (val) {
          this.$emit("change", val.join("|"));
        }
      },
    },

    value: {
      handler(val) {
        if (typeof val === "number") val += "";
        this.innerValue = val;
        if (val) {
          this.valueList = val.split("|");
        }
      },
      immediate: true,
    },
    innerValue: {
      handler(val) {
        this.$emit("change", val);
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
