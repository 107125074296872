<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder size="big" :zIndex="zIndex" title="病变编辑" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>病变部位</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_lesion_site"
          :value.sync="nerveAvmTableInfo.lesionSite"
        />
      </div>
      <div class="item-row">
        <span>引流动脉数量</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="条"
          v-model="nerveAvmTableInfo.drainingArteryCount"
        />
      </div>
      <div class="item-row">
        <span>引流动脉名称</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          v-model="nerveAvmTableInfo.drainingArteryName"
        />
      </div>
      <div class="item-row">
        <span>引流静脉数量</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="条"
          v-model="nerveAvmTableInfo.drainageVeinCount"
        />
      </div>
      <div class="item-row">
        <span>引流静脉名称</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          v-model="nerveAvmTableInfo.drainageVeinName"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>是否合并动脉瘤</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_combined_aneurysm"
          :value.sync="nerveAvmTableInfo.combinedAneurysm"
        />
      </div>
      <div class="item-row">
        <span>AVM类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_avm_type"
          :value.sync="nerveAvmTableInfo.avmType"
        />
      </div>
      <div class="item-row">
        <span>AVM分级</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_avm_level"
          :value.sync="nerveAvmTableInfo.avmLevel"
        />
      </div>
      <div class="item-row">
        <span>AVM大小</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_avm_size"
          :value.sync="nerveAvmTableInfo.avmSize"
        />
      </div>
      <div class="item-row">
        <span>所在脑区功能</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_function_brain_region"
          :value.sync="nerveAvmTableInfo.functionBrainRegion"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>静脉引流方式</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_vein_drainage_mode"
          :value.sync="nerveAvmTableInfo.veinDrainageMode"
        />
      </div>
      <div class="item-row">
        <span>既往有无介入治疗史</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_has_interventional_therapy"
          :value.sync="nerveAvmTableInfo.hasInterventionalTherapy"
        />
      </div>
      <div class="item-row">
        <span>既往弹簧圈/Onyx植入情况</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_has_coil_implantation"
          :value.sync="nerveAvmTableInfo.hasCoilImplantation"
        />
      </div>
      <div class="item-row">
        <span>既往弹簧圈/Onyx类型</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_coil_type"
          :value.sync="nerveAvmTableInfo.coilType"
        />
      </div>
      <div class="item-row">
        <span>既往弹簧圈植入数量</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="枚"
          v-model="nerveAvmTableInfo.coilCount"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>既往Onyx胶植入数量</span>
        <cqt-input
          :isReadonly="isReadonly"
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          size="mini"
          unit="ml"
          v-model="nerveAvmTableInfo.onyxCount"
        />
      </div>
      <div class="item-row">
        <div>
          <span>侧支循环分级</span>
          <div
            @mouseenter="isExitHover = true"
            @mouseleave="isExitHover = false"
            class="menus"
            style="float: right"
          >
            <i class="el-icon-warning" v-show="!isExitHover"></i>
            <i v-show="isExitHover" class="el-icon-warning" slot="reference" />
            <el-popover placement="bottom" width="400" trigger="manual" v-model="isExitHover">
              <img :src="exitActive" style="width: 400px; height: 250px" />
            </el-popover>
          </div>
        </div>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/AVM_information_collateral_circulation_level"
          :value.sync="nerveAvmTableInfo.collateralCirculationLevel"
        />
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <el-col v-show="!isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-radio label="择期动静脉畸形栓塞术" v-model="handlingOpinions">
          择期动静脉畸形栓塞术
        </el-radio>
        <el-radio label="择期显微手术治疗" v-model="handlingOpinions">择期显微手术治疗</el-radio>
        <el-radio label="择期伽马刀治疗" v-model="handlingOpinions">择期伽马刀治疗</el-radio>
        <el-radio label="药物治疗" v-model="handlingOpinions">药物治疗</el-radio>
        <el-radio label="本次治疗" v-model="handlingOpinions">本次治疗</el-radio>
      </el-col>
      <el-col v-show="isReadonly" :span="14" title="处理意见">
        <el-divider content-position="left">处理意见</el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="handlingOpinions">
          {{ handlingOpinions }}
        </el-tag>
      </el-col>
      <el-col v-show="!isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <current-checkbox-select
          v-if="handlingOpinions === '本次治疗'"
          v-model="currentTreatment"
          :currentTreatment="this.currentTreatment"
        ></current-checkbox-select>
      </el-col>
      <el-col v-show="isReadonly" :span="10">
        <el-divider v-if="handlingOpinions === '本次治疗'" content-position="left">
          本次治疗
        </el-divider>
        <el-tag type="info" style="margin-left: 20px" v-model="currentTreatment">
          {{ currentTreatment }}
        </el-tag>
      </el-col>
    </div>
    <div class="button-panel">
      <el-button
        <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
export default {
  name: 'NerveAneurysmInfoDialog',
  components: {
    CmDialogBorder,
    CurrentCheckboxSelect,

    CqtSelect,
    CqtInput
  },
  props: {
    nerveAvmId: {
      type: String,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      exitActive: require('@/assets/img/nerve/cezhi.png'),
      isExitHover: false, //是否悬浮到图标上
      handlingOpinions: ' ',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      currentTreatment: '',
      nerveAvmTableInfo: {
        nerveAvmId: null, //动静脉畸形主表id
        lesionSite: null, //病变部位
        drainingArteryCount: null, //引流动脉数量
        drainingArteryName: null, //引流动脉名称
        drainageVeinCount: null, //引流静脉数量
        drainageVeinName: null, //引流静脉名称
        combinedAneurysm: null, //是否合并动脉瘤
        avmType: null, //AVM类型
        avmLevel: null, //AVM分级
        avmSize: null, //AVM大小
        functionBrainRegion: null, //所在脑区功能
        veinDrainageMode: null, //静脉引流方式
        hasInterventionalTherapy: null, //既往有无介入治疗史
        hasCoilImplantation: null, //既往弹簧圈/Onyx植入情况
        coilType: null, //既往弹簧圈/Onyx类型
        coilCount: null, //既往弹簧圈植入数量
        onyxCount: null, //既往Onyx胶植入数量
        collateralCirculationLevel: null, //侧支循环分级
        handlingOpinion: null //处理意见
      }
    }
  },
  watch: {
    handlingOpinions: {
      handler(val) {
        this.nerveAvmTableInfo.handlingOpinion = val
      }
    },

    currentTreatment: {
      handler(val) {
        this.nerveAvmTableInfo.handlingOpinion = '本次治疗'

        if (val && val.length > 0) {
          this.nerveAvmTableInfo.handlingOpinion = ['本次治疗', ...val.split('|')].join('+')
        }
      }
    }
  },
  created() {},
  methods: {
    Show(bloodVessel) {
      this.nerveAvmTableInfo = {}
      this.currentTreatment = ''
      if (bloodVessel) this.nerveAvmTableInfo = deepClone(bloodVessel)

      let sugs = this.nerveAvmTableInfo.handlingOpinion?.split('+') || []
      this.handlingOpinions = sugs[0] || ''
      if (sugs.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }

      this.isShow = true
    },
    Save() {
      if (this.isReadonly) return
      if (this.nerveAvmTableInfo.lesionSite === null || this.nerveAvmTableInfo.lesionSite === '') {
        return this.$message.warning('病变部位不能为空')
      }
      // if (this.nerveAvmId != '-1' && this.nerveAvmId != '') {
      var idx = this.nerveAvmTableInfo.handlingOpinion?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.nerveAvmTableInfo.handlingOpinion.substr(0, idx + 1)
        var b = this.nerveAvmTableInfo.handlingOpinion.substr(idx + 2)
        this.nerveAvmTableInfo.handlingOpinion = a + b
      }
      this.$emit('save', this.nerveAvmTableInfo)
      this.Close()
      // }
    },

    Close() {
      this.isShow = false
      // this.handlingOpinions = ''
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
